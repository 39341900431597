.cardmain {
  position: relative;
  z-index: 10;
}

.card {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.etykietaimg {
  pointer-events: none;
}
