.bar {
  position: fixed;
  width: 100%;
  height: 70px;
  background-color: white;
  bottom: 0px;
  z-index: 10;
}

.inline {
  width: 100%;
  display: inline-grid;
  grid-template-columns: 5% 20% 30% 40% 5%;
  justify-content: space-evenly;
}

.inline_mkdin_logo {
  border-top: 1px solid rgb(0, 0, 0);
  position: relative;
  line-height: 70px;

  /* background-color: bisque; */
}

.mkdinlogoimg {
  position: relative;
  display: block;
  width: 140px;
  top: 2px;
  margin-left: 0px;
  margin-right: auto;
}

.inlinelicense {
  top: 10px;
  width: 200px;
  position: relative;
  line-height: 15px;
  font-family: VarelaRound;
  word-wrap: break-word;
  font-size: 9px;
  color: #a3a3a3;
}

.inlineyear {
  float: right;
  font-family: VarelaRound;
  color: #a3a3a3;
  font-size: 16px;
}

.inlineitem {
  border-top: 1px solid rgb(0, 0, 0);
  position: relative;
  line-height: 70px;
  /* background-color: rgb(100, 171, 101); */
}
