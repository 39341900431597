.box {
  position: fixed;
  height: 60px;
  top: 61px;
  z-index: 10;
  background-color: rgb(255, 255, 255);
  width: 100%;
}

.filters {
  margin-top: 15px;
  width: 600px;
  margin-left: calc(5vw + 90px);
}

.filtersarray {
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  width: 100%;
}

.filteroption {
  height: 20px;

  position: relative;
  color: #a3a3a3;
  border-radius: 15px;
  stroke: #a3a3a3;
}

.filteroption:hover {
  background-color: rgba(71, 71, 71, 0.442);
  color: #ffffff;
  stroke: #ffffff;
  cursor: pointer;
}

.filteroptiontext {
  margin-left: 10px;
  font-family: VarelaRound;
  font-size: 12px;

  line-height: 20px;
}

.arrico {
  margin-left: 5px;
  position: relative;
}
