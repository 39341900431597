.nextprevbar {
  position: fixed;
  display: inline-grid;
  grid-template-columns: 5% 90% 5%;
  justify-content: space-evenly;
  bottom: 50%;
  width: 100%;
  height: 100px;
  z-index: 3;
  font-family: VarelaRound;
}

.next {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 100px;
  stroke: #a3a3a3;
  /* background-color: rgb(255, 255, 255); */
}

.next:hover {
  background-color: rgba(175, 175, 175, 0.187);
  cursor: pointer;
}

.prev {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 100px;
  stroke: #a3a3a3;
  /* background-color: rgb(255, 255, 255); */
}
.prev:hover {
  background-color: rgba(175, 175, 175, 0.187);
  cursor: pointer;
}
