.mainbox {
  position: relative;
  font-family: VarelaRound;
  width: 80%;
  margin-left: 10%;
  top: 100px;
  /* right: 10%; */
  max-width: 800px;
}

.paragraph {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  font-size: 16px;
  line-height: 22px;
  line-height: 26px;
}

.header {
  position: relative;
  letter-spacing: 0.06em;
  line-height: 26px;
  text-transform: uppercase;
  font-size: 16px;
}

.end {
  height: 300px;
  background-color: white;
}
