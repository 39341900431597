.option {
  background-color: rgb(255, 255, 255);
  width: 130px;
  height: 30px;
}

.option:hover {
  background-color: rgb(224, 224, 224);
  cursor: pointer;
}

.optionselected {
  background-color: rgb(247, 181, 181);
  width: 130px;
  height: 30px;
}

.optiontext {
  position: relative;
  text-transform: lowercase;
  left: 10%;
  color: #a3a3a3;
  top: 50%;
  transform: translateY(-50%);
  font-family: VarelaRound;
  font-size: 12px;
}
