.dropdown {
  position: absolute;
  background-color: rgb(144, 144, 144);
}

.dropdownoptions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 500px;
}

/* .dropdownoptions > * {
  flex: 1 1 30px;
} */
