.viewcard {
  position: fixed;
  top: 61px;
  width: 100%;
  z-index: 20;
  background-color: rgb(255, 255, 255);
  /* height: 100%; */
  bottom: 70px;
}

.return {
  position: fixed;
  top: 61px;
  left: 0px;

  background-color: rgb(255, 255, 255);
  width: 5%;
  height: 100px;
}

.return:hover {
  background-color: rgba(175, 175, 175, 0.187);
  cursor: pointer;
}

.returnico {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 100px;
  top: 30%;
  stroke: #909090;
}

.returnico:hover {
  stroke: white;
}

.row {
  height: 100%;
  width: 90%;
  margin-left: 5%;
}

.etykieta {
  float: left;
  width: 66%;
  /* background-color: rgba(0, 0, 255, 0.438); */
  height: 80%;
  margin-top: 80px;
}

.etykietaimg {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 90%;
  pointer-events: none;
}

.etykietadescription {
  font-family: VarelaRound;
  float: left;
  width: 33%;
  /* background-color: rgba(165, 42, 42, 0.323); */
  /* height: 90%; */
  margin-top: 80px;
}

.descriptiontable {
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 8px;
  border-collapse: collapse;
  height: 500px;
  width: 100%;
}

.descriptiontable th {
  /* border: 1px solid rgba(0, 0, 0, 0); */
  padding: 8px;
  border-bottom: 1px solid #cdcdcd;
  border-collapse: collapse;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}

/* TABLE */

.tableheader {
  font-family: VarelaRound;
  /* font-weight: 600; */
  font-size: 20px;
  line-height: 18px;
  color: #7a7a7a;
  text-align: left;
}

.tablecatsheader {
  font-family: VarelaRound;
  /* font-weight: 600; */
  font-size: 16px;
  line-height: 18px;
  color: #7a7a7a;
  text-align: left;
}

.tablecontents {
  font-family: VarelaRound;
  /* font-weight: 600; */
  font-size: 14px;
  line-height: 18px;
  color: #1e1e1e;
  text-align: right;
}
