.griddisplay {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  top: 80px;
  /* background-color: rgba(255, 255, 0, 0.257); */
  justify-content: center;
  align-items: baseline;
  z-index: 5;
  width: 90%;
}

.griddisplay > div {
  /* background-color: antiquewhite; */
  width: 250px;
  margin: 15px;
  height: 250px;
}

.fillertop {
  height: 20px;

  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.fillerbottom {
  height: 130px;

  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
