.bar {
  position: fixed;
  width: 90%;
  margin-left: 5%;
  z-index: 10;
  background-color: white;
  top: 0px;
}

.headerbar {
  display: inline-grid;
  grid-template-columns: 100px auto 1% 14%;
  height: 60px;
  width: 100%;
  z-index: 11;
  border-bottom: 1px solid rgb(0, 0, 0);
}

.headerbartitle {
  display: flex;
  align-items: flex-end;

  font-family: VarelaRound;

  font-size: 20px;

  margin-bottom: 15px;
}

.headerbarsubtitle {
  display: flex;
  align-items: flex-end;

  font-family: VarelaRound;

  font-size: 12px;

  margin-bottom: 18px;
}

.headerbaraboutlink {
  display: flex;
  align-items: flex-end;

  font-family: VarelaRound;
  position: relative;
  margin-left: 10px;

  margin-bottom: 20px;
}

.headerbarlangselect {
  display: flex;
  align-items: flex-end;

  font-family: VarelaRound;
  position: relative;
  margin-left: 10px;

  margin-bottom: 20px;
}

.headerbaritem {
  position: relative;
  margin: 0;
  margin-top: 15px;
}
