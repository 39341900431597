.window {
  position: absolute;
  position: relative;
  top: 100px;
  width: 100px;
  height: 20px;
  /* background-color: rgb(126, 126, 126); */
  z-index: 500;

  font-family: VarelaRound;
  font-size: 19px;
  color: rgba(0, 0, 0, 0.479);

  line-height: 20px;
}
